<template>
  <div style="width: 100%">
    <v-card v-if="!loading">
      <v-container>
        <v-row align="center">
          <v-col :cols="desktop ? 3 : 6" v-if="getCorporative && this.$route.name === 'dashboard'">
            <div class="mb-2">Gerencia</div>
            <v-autocomplete
              v-model="serviceType"
              label="Selecciona una gerencia"
              name="businessArea"
              :items="availableServiceTypes"
              hide-no-data
              hide-selected
              item-text="name"
              item-value="code"
              required
              dense
              outlined
              color="#7319D5"
            />
          </v-col>
          <v-col :cols="desktop ? 3 : 6" v-if="this.$route.name === 'dashboard'">
            <div class="mb-2">Gerencia</div>
            <v-autocomplete
              v-model="management"
              label="Selecciona un gerencia"
              name="businessArea"
              :items="availableManagements"
              hide-no-data
              hide-selected
              item-text="name"
              item-value="code"
              required
              dense
              outlined
              color="#7319D5"
            />
          </v-col>
          <v-col :cols="desktop ? 3 : 6" v-if="this.$route.name === 'dashboard'">
            <div class="mb-2">Área</div>
            <v-autocomplete
              v-model="businessArea"
              label="Selecciona un área"
              name="businessArea"
              :items="availableBusinessArea"
              hide-no-data
              hide-selected
              item-text="name"
              item-value="code"
              required
              dense
              outlined
              color="#7319D5"
            />
          </v-col>

          <v-col :cols="desktop ? 6 : 12" v-if="this.$route.name !== 'dashboard'">
            <div class="mb-2">Zona geográfica</div>
            <v-autocomplete
              v-model="geoSector"
              label="Selecciona una zona geografica"
              name="geoSector"
              :items="availableGeoSectors"
              hide-no-data
              hide-selected
              item-text="name"
              item-value="code"
              required
              dense
              outlined
              color="#7319D5"
            />
          </v-col>

          <v-col :cols="desktop ? 2 : 6">
            <v-btn
              rounded
              color="tway-violet"
              class="button-text elevation-8 white--text"
              @click="applyFilter()"
              :disabled="canFilter"
            >
              <span>ver resultado</span>
            </v-btn>
          </v-col>
          <v-col :cols="desktop ? 2 : 6">
            <v-btn rounded outlined color="deep-purple accent-4" @click="setGlobal()">
              <span>resultado global</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-dialog v-model="modal" max-width="600">
      <v-card>
        <v-container>
          <v-container style="max-width: 500px; text-align: center">
            <v-card-text
              class="mt-5"
              style="
                font-family: 'Josefin Sans', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 25px;
                line-height: 30px;
                color: #333333;
              "
            >
              No se encontro un indice para el segmento seleccionado
            </v-card-text>
          </v-container>
          <v-row justify="center" class="mb-5 mt-2">
            <v-btn
              outlined
              class="button-text tway-violet white--text"
              rounded
              @click="
                () => {
                  this.modal = false;
                }
              "
            >
              aceptar
            </v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GeoSectors from "@/utils/GeoSectors";
export default {
  data() {
    return {
      geoSector: "",
      serviceType: "",
      businessArea: "",
      management: "",
      modal: false,
      availableServiceTypes: [
        { name: "Clientes", code: "ST_001" },
        { name: "Soporte", code: "ST_002" },
      ],
      availableManagements: [],
      availableBusinessArea: [],
      availableGeoSectors: GeoSectors,
      isLoading: false,
    };
  },
  props: {
    managements: {
      type: Array,
      default: () => [],
    },
    businessAreas: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    ...mapActions("ImtdStore", ["setRecommendations", "setRecommendationsBci"]),
    ...mapActions("ImoStore", ["getRecomendations", "init"]),

    ...mapActions("CompanyStore", ["setBusinessAreas"]),
    async applyFilter() {
      this.isLoading = true;
      if (this.$route.name === "dashboard") {
        if (typeof this.businessArea == "string") {
          try {
            if (this.getCorporative) {
              let data = {};
              data.clientId = this.getClientId;
              this.businessArea !== ""
                ? (data.businessArea = this.businessArea)
                : (data.serviceType = this.serviceType);
              await this.setRecommendationsBci(data);
            } else {
              let filterCriteria = {
                management: this.management,
                businessArea: this.businessArea,
              };
              await this.setRecommendations({
                filterCriteria: filterCriteria,
                clientId: this.getClientId,
              });
            }
            this.isLoading = false;
          } catch (error) {
            this.management = "";
            this.businessArea = "";
            this.serviceType = "";
            this.modal = true;
            this.isLoading = false;
          }
        }
      } else {
        if (typeof this.geoSector == "string") {
          try {
            await this.getRecomendations(this.geoSector);
            this.isLoading = false;
          } catch (error) {
            this.geoSector = "";
            this.modal = true;
            this.isLoading = false;
          }
        }
      }
    },
    async setGlobal() {
      if (this.$route.name === "dashboard") {
        this.isLoading = true;
        const token = await this.$recaptcha("dashboard");
        await this.setBusinessAreas(token);
        await this.setRecommendations({ clientId: this.getClientId }).then(() => {
          this.management = "";
          this.businessArea = "";
          this.serviceType = "";
          this.isLoading = false;
        });
      } else {
        await this.getRecomendations().then(() => {
          this.geoSector = "";
          this.isLoading = false;
        });
      }
    },
  },
  computed: {
    ...mapGetters("authentication", ["getCorporative"]),
    ...mapGetters("UserStore", ["getClientId"]),
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
    canFilter() {
      if (this.$route.name == "dashboard") {
        if (this.getCorporative) {
          return this.serviceType == "";
        }
        return this.management == "";
      } else {
        return this.geoSector == "";
      }
    },
  },
  created() {
    this.availableManagements = this.managements;
    this.availableBusinessArea = this.businessAreas;
  },
  watch: {
    managements() {
      this.availableManagements = this.managements;
    },
    businessAreas() {
      this.availableBusinessArea = this.businessAreas;
    },
    management() {
      if (this.management != "") {
        let data = this.availableManagements.find((element) => element.code == this.management);
        this.availableBusinessArea = data.businessAreas;
      }
    },
    async serviceType() {
      this.businessArea = "";
      const token = await this.$recaptcha("dashboard");
      await this.setBusinessAreas(token);
      let clientAreas = ["BA_027", "BA_033"];
      if (this.serviceType === "ST_001") {
        this.availableBusinessArea = this.availableBusinessArea.filter((e) => clientAreas.includes(e.code));
      }
      if (this.serviceType === "ST_002") {
        this.availableBusinessArea = this.availableBusinessArea.filter((e) => !clientAreas.includes(e.code));
      }
    },
    isLoading() {
      this.$emit("loading", this.isLoading);
    },
  },
};
</script>

<style></style>
